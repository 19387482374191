import { post } from '../../src/util';

export const successRedirectPath = (orderSubscriptionId) =>
    `/order_subscriptions/${orderSubscriptionId}/subscription_success`;

export const failedRedirect = (orderSubscriptionId) => {
  return window.location = `/order_subscriptions/${orderSubscriptionId}/subscription_failed`;
};

export const createSubscription = async (planId, orderSubscriptionId) => {
  try {
    const { data } = await post(`/api/paypal_subscriptions/create`, { plan_id: planId });
    return data.subscription_id;
  } catch (err) {
    console.error("Error creating subscription:", err);
    failedRedirect(orderSubscriptionId);
  }
};

export const onApprove = async (orderSubscriptionId, subscriptionId) => {
  try {
    await post(`/api/paypal_subscriptions/capture.json?order_subscription_id=${orderSubscriptionId}&subscription_id=${subscriptionId}`);
    window.location = successRedirectPath(orderSubscriptionId);
  } catch (err) {
    console.error("Error approving subscription:", err);
    failedRedirect(orderSubscriptionId);
  }
};

export const showError = (errObj, orderSubscriptionId) => {
  const msg = "Sorry, your subscription could not be processed.";
  Application.Classes.Toastr.error(msg);
  console.error("Error:", errObj);
  failedRedirect(orderSubscriptionId);
};