import React, {useMemo, useState} from 'react'
import SweetAlert2 from "react-sweetalert2";
import style from './BuyButton.module.scss'
import BuyButtonShortContent from "./shortContent";

const BuySubscriptionButton = ({title, price, currency, user_id, plan_id, subscription_kind}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false)

    console.log('subscription_kind', subscription_kind)

    const buttonTitle = useMemo(() => {
        if (title) return title
    }, [title])

    return (
        <>
            <button
                className='subscribe-button btn  btn-primary w-100 h-40i br-8i pt-12i button-text'
                onClick={() => setModalIsOpen(true)}

            >
                {buttonTitle}
            </button>
            {<SweetAlert2
                backdrop="rgba(0, 0, 0, 0.87)"
                show={modalIsOpen}
                onResolve={() => setModalIsOpen(false)}
                showConfirmButton={false}
            >
                {
                    modalIsOpen && (
                        <div className={style.contentContainer}>
                            {
                                <BuyButtonShortContent title={title} price={price} currency={currency} subscriptionKind={subscription_kind} planId={plan_id} userId={user_id}/>
                            }
                            <i className={`fa fa-close ${style.closeIcon}`} onClick={() => setModalIsOpen(false)}></i>
                        </div>
                    )
                }
            </SweetAlert2>}
        </>
    )
}

export default BuySubscriptionButton
