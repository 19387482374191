import React from 'react';
import {
    PayPalScriptProvider,
    PayPalButtons
} from "@paypal/react-paypal-js";
import {createSubscription, onApprove} from './actions';

const PayPalSubscription = ({title, price, currency, userId, planId, subscriptionKind, orderSubscriptionId}) => {
    const clientId = process.env.PAY_PAL_CLIENT;

    console.log("clientId", process.env);

    return (
        <PayPalScriptProvider
            options={{
                clientId: clientId,
                intent: "subscription",
                vault: true,
            }}
        >
            <div>
                <h3>Subscription for {subscriptionKind === "monthly" ? "1 month" : "1 year"}</h3>
                <PayPalButtons
                    createSubscription={(data, actions) => {
                        return actions.subscription.create({
                            plan_id: planId,
                            custom_id: userId
                        });
                    }}
                    onApprove={(data) => {
                        const subscriptionId = data.subscriptionID;
                        onApprove(orderSubscriptionId, subscriptionId);
                    }}
                    onError={(error) => {
                        console.error("PayPal Error:", error);
                        showError(error, orderSubscriptionId);
                    }}
                    onCancel={() => {
                        console.log("Subscription cancelled.");
                        failedRedirect(orderSubscriptionId);
                    }}
                />
            </div>
        </PayPalScriptProvider>
    );
};

export default PayPalSubscription;