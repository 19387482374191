import React, {useEffect, useState} from 'react'
import axios from "axios";
import PayPalSubscription from "../../PayPalSubscription";
import style from '../BuyButton.module.scss'

const fetcher = (url, params) => axios.get(url, {params}).then(res => res.data)

export default function BuyButtonShortContent({title, price, currency, subscriptionKind, planId, userId}) {
  const createOrderSubscriptionPath = `/api/order_subscriptions.json?subscription_kind=${subscriptionKind}&user_id=${userId}&price=${price}&payment_provider=paypal`
  const [data, setData] = useState(null)

  useEffect(() => {
    axios.post(createOrderSubscriptionPath).then(({data}) => setData(data))
  }, [])

  if (!data) return <p>Loading...</p>

  return (
    <>
        {
            data.baby && (
                <div
                    className={style.avatar}
                    style={{
                        width: '100px',
                        height: '100px',
                        backgroundImage: `url(${data.user.avatar})`,
                        backgroundSize: 'cover',
                        margin: '10px auto'
                    }}
                ></div>
            )
        }
      <h5 className={style.subscriptionTitle}>{title}</h5>

      {data.user && <p className={style.userName}>{data.user.name}</p>}
      <PayPalSubscription
        title={title}
        price={price}
        currency={currency}
        userId={userId}
        planId={planId}
        subscriptionKind={subscriptionKind}
        orderSubscriptionId={data.id}
        fetcher={fetcher}
      />
    </>
  )
}